import React from "reactn"
import PropTypes from 'prop-types'
import { Box, Flex } from "@chakra-ui/layout"
import Search from "./search"
import { LayoutMain } from "./layoutMain"
import { Footer } from "./footer"
export function Layout({ children,location, pageContext }) {
  const {
    voucher,
    collectionURL
  } = pageContext
  return (
    <Box id="layoutVoucher">
          <Flex flexDirection={'column'} minHeight={'100vh'} >            
            <LayoutMain location={location}>
              <Box id="layoutSerchBox" ml={[5, 5, 10, 10]} mr={[5, 5, 5, 5]}>
                {children}
                <Search pageContext={pageContext} collectionURL={collectionURL} location={location} isVoucher={true} collection={voucher} />
              </Box>
              </LayoutMain >
          </Flex>    
          <Footer isVoucher={true}/>            
    </Box>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  breadcrumb: PropTypes.object
}

Layout.defaultProps = {
  breadcrumb: {}
}