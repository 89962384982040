import React  from "reactn"
import { graphql } from "gatsby"
import { Layout } from "../../components/layoutVoucher"
import { Seo } from "../../components/seo"
import Products from "../../components/products"

export default function Voucher({ location, data: { voucher } }) {
  const {
    descriptionHtml,
    metaDescription,
    metaTitle,
    image
  } = voucher

  const {
    pageTitle,
    social_image,
    social_images
  } = voucher.fields
  
  return (
    <>
    <Seo  metaTitle={metaTitle} metaDescription={metaDescription} socialImage={social_image} socialImages={social_images} og_type={'article'} location={location} />
      <Products title={pageTitle} html={descriptionHtml} titleImage={image} socialImage={social_image}/>
    </>
  )
}

Voucher.Layout = Layout
export const query = graphql`
  query($id: String!) {
    voucher: shopifyCollection(id: { eq: $id }) {
      internal {
        type
      }
      fields {
        pageTitle
        social_image
        social_images
      }
      pageTitle
      metaTitle
      metaDescription
      metaImage
      descriptionHtml
      image {
        gatsbyImageData(
          width: 100
        )
      }
    }    
  }
`
